let docHeight;
const maxSameUpdates = 10;
let sameUpdatesCount = 0;

/**
* Updates the size of the iframe element with the size of the document.
* @todo Also check for the container size.
* @todo Make it configurable.
*/
export function fitToContent() {
    var iframeEl = window.frameElement,
    docEl = document.documentElement;

    let parentOrigin = (window.location.ancestorOrigins && window.location.ancestorOrigins[0]) || document.referrer;
    const isSameOrigin = parentOrigin === document.location.origin;
    if (!parentOrigin || isSameOrigin) {
        parentOrigin = "*";
    }

    if (docHeight === docEl.offsetHeight) {
        sameUpdatesCount++;
    } else {
        sameUpdatesCount = 0;
    }

    if (sameUpdatesCount <= maxSameUpdates) {
        docHeight = docEl.offsetHeight;
        // Send to parent
        window.parent.postMessage({
            'type': 'height',
            'value': docHeight
        }, parentOrigin);
    }

    setTimeout(fitToContent, 100);
}