import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCompanyStyles } from "../services/api";
import "../styles/ApplicationSuccess.css";
import Loader from "./Loader";

const ApplicationSuccess = ({ company }) => {
    const [companyStyles, setCompanyStyles] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompanyStyles = async () => {
            try {
                if (company) {
                    const styles = await getCompanyStyles(company);
                    setCompanyStyles(styles);
                }
            } catch (err) {
                setError("Company not found");
            } finally {
                setLoading(false);
            }
        };

        fetchCompanyStyles();
    }, [company]);

    useEffect(() => {
        if (companyStyles && companyStyles.styles) {
            const styleElement = document.createElement("style");
            styleElement.textContent = companyStyles.styles;
            document.head.appendChild(styleElement);

            return () => {
                document.head.removeChild(styleElement);
            };
        }
    }, [companyStyles]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className={`application-success ${company || ""}`}>
            <header>
                {companyStyles?.logo && <img src={companyStyles.logo} alt="Company Logo" className="company-logo" />}
                <h1>Application Submitted Successfully</h1>
            </header>
            <p>
                Your application is received and will be reviewed in the coming days. You will be contacted if your
                profile is found suitable.
            </p>
            <Link to={`/${company}`} className="return-link">
                Return to Application Form
            </Link>
        </div>
    );
};

export default ApplicationSuccess;
