import React from "react";
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import ApplicationForm from "./components/ApplicationForm";
import ApplicationSuccess from "./components/ApplicationSuccess";
import { fitToContent } from "./frame-resizer";
import { lazy } from "react";
const IFrameTestForm = lazy(() => import("./components/IFrameTest"));

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/iframe-test" element={<IFrameTestForm />} />
                <Route path="/:company" element={<CompanyForm />} />
                <Route path="/:company/application-success" element={<CompanySuccess />} />
            </Routes>
        </Router>
    );
}

function CompanyForm() {
    fitToContent();

    const { company } = useParams();

    //TODO: rewrite
    let photoRequired = company === "pagliaro" ? true : false;

    return <ApplicationForm company={company} photoRequired={photoRequired} />;
}

function CompanySuccess() {
    const { company } = useParams();
    return <ApplicationSuccess company={company} />;
}

export default App;
