import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "http://localhost:3000";

export const getCompanyStyles = async (company) => {
    const response = await axios.get(`${API_BASE_URL}/api/company/${company}/styles`);
    return response.data;
};

export const getCountries = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/data/countries.json`);
        return response.data;
    } catch (error) {
        console.error("Error fetching countries:", error);
        return [];
    }
};

export const submitApplication = async (formData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/inquery`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error submitting application:", error);
        throw error;
    }
};
